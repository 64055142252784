import { Middleware } from '@nuxt/types'
import { createNuxtMiddleware } from '@alao-frontend/utils'

const COOKIE_NAME = 'partner_referral'

const siteLevelReferralLink: Middleware = ctx => createNuxtMiddleware(
  ctx,
  (
    utils,
  ) => {
    const { route } = ctx

    if (!route.query.partner) {
      return
    }

    const context = route.query.partner.toString()

    const {
      APP_DOMAIN: domain,
    } = ctx.$config

    utils.setCookie(COOKIE_NAME, context, {
      domain,
      path: '/',
    })
  })

export default siteLevelReferralLink
