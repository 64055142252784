
import { defineComponent, PropType, ref } from 'vue'

import { getCookie } from '@alao-frontend/utils'

import { useSale } from '~/composables/sales.composable'
import { AssistantOfferContext } from '~/modules/assistant-v2'

export default defineComponent({
  name: 'AssistantOrderCTA',

  props: {
    offerContext: {
      type: Object as PropType<AssistantOfferContext>,
      required: true,
    },
    isDiscountCodeApplied: {
      type: Boolean,
      required: true,
    },
    appliedDiscountCode: {
      type: String,
      required: true,
    },
  },

  setup (props) {
    const isLoading = ref(false)

    const { creatOrderDirectly } = useSale(props.offerContext.offerId)

    return {
      isLoading,
      creatOrderDirectly,
    }
  },

  methods: {
    async onClick () {
      try {
        this.isLoading = true
        const promoCode = this.isDiscountCodeApplied ? this.appliedDiscountCode : undefined

        const partner = getCookie('partner_referral')

        await this.creatOrderDirectly(promoCode, partner)
        this.$router.push(this.getOrderUrl())

        this.$emit('order-button-click')
      } catch (e) {
        this.$sentry.captureException(e)
        this.$notify({
          title: 'Error',
          message: this.$t('500.title').toString(),
          duration: 3000,
        })
      } finally {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      }
    },

    getOrderUrl () {
      const query: Record<string, any> = {
        id: this.offerContext.offerId,
        type: this.offerContext.offerType,
        order: true,
        slug: this.offerContext.offerSlug,
        order_id: getCookie('order_id'),
        discount_code: this.isDiscountCodeApplied
          ? this.appliedDiscountCode
          : undefined,
      }

      return this.localePath({
        name: 'order-slug',
        params: {
          slug: 'contact_detail',
        },
        query,
      })
    },
  },
})
